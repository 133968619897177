import React from 'react';
import { useNavigate } from 'react-router-dom';
import { APP_COLOR, Job_apply } from '../Constant';
import {SiteRoutes} from '../../Helpers/site_routes'
import './Content.css'

const services = [
  {
    title: 'HR Consulting',
    description: 'Organizational Capability Building: Aligning Vision, Mission, Values & Objectives - Driving Performance, Ensuring Compliance',
    buttonLabel: 'Discover HR Solutions',
    buttonLabe2: 'Book a Consultation',
    imgSrc: '/images/hr_consulting.png',
  },
  {
    title: 'Recruitment and Outsourcing',
    description: 'We deliver quality sourcing and placements with a quick turnaround time and high stick-rate.',
    buttonLabel: 'Explore Our Portal',
    buttonLabe2: 'See FAQs',
    imgSrc: '/images/recruitment.png',
  },
  // We specialize in headhunting the right fit across all verticals, offering 360-degree candidate assessments. Our focus ensures business and cultural alignment for every hire.
  {
    title: 'Training and Development',
    description: 'Identifying Gaps, Enhancing Productivity, Driving Excellence & Upskilling Workforce',
    buttonLabel: 'Learn More',
    buttonLabe2: 'Request a Consultation',
    imgSrc: '/images/upskilling.jpg',
  },
];

const ServiceCards = () => {
  const navigate = useNavigate();

  const handleButtonClick = (buttonLabel) => {
    switch (buttonLabel) {
      case 'Learn More':
        document.getElementById('video-section')?.scrollIntoView({ behavior: 'smooth' });
        break;
      case 'Explore Our Portal':
        window.open(Job_apply.web_portal, '_blank');
        break;
      case 'Discover HR Solutions':
        navigate(SiteRoutes.hrConsulting);
        break;
      default:
        console.warn('No action defined for this button.');
    }
  };

  const handleButtonClick2 = (buttonLabe2) => {
    window.scrollTo(0, 0);
    switch (buttonLabe2) {
      case 'Request a Consultation':
        navigate('/contact');
        break;
      case 'See FAQs':
        navigate('/recruitment');
        break;
      case 'Book a Consultation':
        navigate('/contact');
        break;
      default:
        console.warn('No action defined for this button.');
    }
  };
  /*className="content-details relative flex flex-col items-center bg-white p-6 rounded-lg shadow-md w-full max-w-sm min-h-[400px] md:min-h-[500px] border-4 
            border-gray-300 hover:border-orange-400 transition-all duration-300 transform hover:scale-105 hover:shadow-lg" */

  return (
    <div className="flex flex-wrap justify-center items-stretch gap-8 sm:gap-12 px-4 py-10">
    {services.map((service, index) => (
      <div
        key={index}
        className="content_details relative flex flex-col items-center bg-white p-6 pt-14 rounded-lg shadow-md w-full max-w-sm border-4 my-10
          border-gray-300 hover:border-green-400 transition-all duration-300 transform hover:scale-105 hover:shadow-lg"
      >
          <div className="absolute -top-20">
            <img
              src={service.imgSrc}
              alt={service.title}
              className="h-40 w-40 object-contain rounded-lg shadow-lg hover:scale-105 transition-transform duration-300 ease-in-out content-image"
            />
          </div>

          <h3 className="text-xl font-semibold mt-16 mb-2 text-center">{service.title}</h3>
          <p className="text-gray-600 text-center mb-6 flex-grow">{service.description}</p>
          <div className="flex flex-row md:flex-col text-wrap gap-4 w-full mt-4 ">
            <button
              style={{ backgroundColor: APP_COLOR }}
              className="text-black font-bold px-4 py-3 md:py-4 rounded-lg hover:bg-green-600 transition flex-1 
              w-full min-h-[50px] md:min-h-[60px] 
              flex items-center justify-center text-center"
              onClick={() => handleButtonClick(service.buttonLabel)}
            >
              {service.buttonLabel}
            </button>
            <button
              className="text-white px-4 py-3 md:py-4 rounded-lg bg-black border-2 border-black 
              transition flex-1 w-full min-h-[50px] md:min-h-[60px] 
              flex items-center justify-center text-center"
              onClick={() => handleButtonClick2(service.buttonLabe2)}
            >
              {service.buttonLabe2}
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ServiceCards;
