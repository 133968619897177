import React, { useEffect } from 'react';
import AboutUs from '../About/Aboutus';
import Content from '../Content/Content';
import Matrices from '../Metrics/Metrics';
import VideoPlayer from './Youtube player/Youtube_Player';
import Testimonials from '../Testimonials/Testimonials';
import { useNavigate } from 'react-router-dom';

import 'aos/dist/aos.css';
import AOS from 'aos';
import SectionWidget from '../Banner/Section';
import ChatProductTable from './Chat Product/ChatProduct';
import './Home.css';
import GridComponent from './grid';

function Home() {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);
  const navigate = useNavigate();

  const handleNavigate = () => {
    window.scrollTo(0, 0);
    navigate('/contact');
  };

  return (
    <>
      <div data-aos="fade-up">
        <AboutUs />
      </div>
      
      <div
        data-aos="fade-up"
        className="text-5xl font-bold text-black mb-6 leading-tight text-center">
        Our Services 💡
      </div>
      <div data-aos="fade-up" bgColor="gray">
        <Content />
      </div>
         <div data-aos="fade-up">
      <GridComponent/>;
      </div>
      <div data-aos="fade-up">
        <Matrices />
      </div>
      {/* <div
        data-aos="fade-up"
        className="divider"
      ></div> */}
      <div data-aos="fade-up">
        <SectionWidget
          title="Looking to Hire Talent?"
          subtitle="90 day free replacement for any exits 🥳"
          buttonText="Get in touch"
          imageSrc={`${process.env.PUBLIC_URL}/images/left.jpeg`}
          onButtonClick={handleNavigate}
          bgColor="#eecc00"
        />
      </div>
      <div data-aos="fade-up">
        <Testimonials />
      </div>
      <div>
        <SectionWidget
          title="Professional Training for Employees"
          subtitle="Supercharge your team! ⚡️⚡️"
          buttonText="See Programs"
          imageSrc={`${process.env.PUBLIC_URL}/images/right.jpeg`}
          onButtonClick={handleNavigate}
          bgColor="#71cffd"
        />
      </div>

      <div data-aos="fade-up">
        <ChatProductTable />
      </div>
      <div data-aos="fade-up">
        <VideoPlayer />
      </div>
    </>
  );
}

export default Home;
