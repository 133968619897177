import React from 'react';
import FeatureCard from './FeatureHomeCard';
import {APP_COLOR} from '../../Constant'

const features = [
  {
    title: "Improved Employee Productivity",
    description: "Streamlining workforce planning and talent development leads to higher employee performance and efficiency.",
    imagePath: "/images/HR1.jpeg",
    bgColor: "#f0f0f0", 
  },
  {
    title: "Enhanced Employee Retention",
    description: "Tailored engagement and reward programs foster a positive work environment, reducing turnover and boosting morale.",
    imagePath: "/images/HR2.jpeg",
    bgColor: "#ffffff",
  },
  {
    title: "Optimized Organizational Growth",
    description: "Strategic planning and skill development ensure alignment with company goals, promoting sustainable growth and scalability.",
    imagePath: "/images/HR3.jpeg",
    bgColor: "#9fd4fe", 
  },
];



function FeatureMain() {
  return (
    <div className="bg-gray-100 p-8">
      <h1 className="text-4xl font-bold text-center mb-8">
        Why <span style={{color:APP_COLOR}}>HR Consulting?</span>
      </h1>
      <div className="flex flex-col md:flex-row gap-6">

      {features.map((feature, index) => (
    <FeatureCard
      key={index}
      title={feature.title}
      description={feature.description}
      imagePath={feature.imagePath}
      bgColor={feature.bgColor}
    />
  ))}
    </div>
    </div>
  );
}

export default FeatureMain;
