import React from 'react';
import CarouselService from './CarouselService';
import ChatSection from './ChatSection';
import FeatureMain from './Feature/FeatureMain'

const Hr_consulting = () => {
  return (
    <>
      <div data-aos="fade-up">
        <FeatureMain />
      </div>
      <div data-aos="fade-up">
        <CarouselService />
      </div>
      <div data-aos="fade-up">
        <ChatSection />
      </div>
    </>
  )
};

export default Hr_consulting;
