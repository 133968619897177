import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Navbar.css';
import { Job_apply } from '../Constant';

const Navbar = () => {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  const NavLinks = {
    HOME: '/',
    HR_CONSULTING: '/hr_consulting',
    RECRUITMENT: '/recruitment',
    CONTACT_US: '/contact',
  };

  const links = Object.keys(NavLinks);

  useEffect(() => {
    const currentPath = location.pathname;
    const activeRoute = Object.keys(NavLinks).find(
      (key) => NavLinks[key] === currentPath
    );
    if (activeRoute) {
      setActiveLink(activeRoute);
    }
  }, [location.pathname]);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleLinkClick = (link) => {
    setActiveLink(link);
    setIsOpen(false);
  };

  const handleApplyNowClick = () => {
    window.open(`${Job_apply.web_portal}`, '_blank');
  };

  return (
    <nav className={`navbar ${scrolled ? 'shadow-xl' : ''} sticky top-0 z-30 w-full px-4 md:px-10`}>
      <Link
        to="/"
        className="flex items-center transition-opacity duration-300"
        onClick={() => handleLinkClick('HOME')}
      >
        <img
          src={`${process.env.PUBLIC_URL}/images/logo.jpeg`}
          alt="Logo"
          className="pt-3 h-20 md:h-32 lg:h-30"
        />
        <img
          src={`${process.env.PUBLIC_URL}/images/slogan.png`}
          alt="Slogan"
          className="h-16 md:h-24 lg:h-20 ml-4"
        />
      </Link>

      <div className="nav-links">
        {links.map((link) => (
          <Link
            key={link}
            to={NavLinks[link]}
            className={`nav-link ${activeLink === link ? 'active' : ''}`}
            onClick={() => handleLinkClick(link)}
          >
            {link.replace('_', ' ')}
          </Link>
        ))}
      </div>

      <button className="button" onClick={handleApplyNowClick}>
        Apply for Jobs
        <svg className="icon" viewBox="0 0 24 24" fill="currentColor">
          <path
            fillRule="evenodd"
            d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm4.28 10.28a.75.75 0 000-1.06l-3-3a.75.75 0 10-1.06 1.06l1.72 1.72H8.25a.75.75 0 000 1.5h5.69l-1.72 1.72a.75.75 0 101.06 1.06l3-3z"
            clipRule="evenodd"
          ></path>
        </svg>
      </button>

      <div className="hamburger" onClick={() => setIsOpen(!isOpen)}>
        {isOpen ? '✖' : '☰'}
      </div>

      {isOpen && (
        <div className="sidebar-overlay" onClick={() => setIsOpen(false)}>
          <div className="sidebar" onClick={(e) => e.stopPropagation()}>
            <button className="close-button" onClick={() => setIsOpen(false)}>
              ✖
            </button>
            <div className="sidebar-links">
              {links.map((link) => (
                <Link
                  key={link}
                  to={NavLinks[link]}
                  className={`sidebar-link button-style ${activeLink === link ? 'active' : ''}`}
                  onClick={() => handleLinkClick(link)}
                >
                  {link.replace('_', ' ')}
                </Link>
              ))}
              <button className="button-style sidebar-apply-button" onClick={handleApplyNowClick}>
                Apply for Jobs
              </button>
            </div>
          </div>
        </div>
      )}
      
    </nav>
  );
};

export default Navbar;
