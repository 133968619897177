import React from 'react';
import './grid.css';

const GridComponent = () => {
    const children = [
        { title: 'Gap Analysis', subtitle: 'Identify gaps', emoji: '🔍' },
        { title: 'Process Improvement', subtitle: 'Optimize workflows', emoji: '⚙️' },
        { title: 'Employee Retention', subtitle: 'Reduce turnover', emoji: '🤝' },
        { title: 'Skilling & Reskilling', subtitle: 'Enhance capabilities', emoji: '📘' },
        { title: 'Productivity Improvement', subtitle: 'Increase efficiency', emoji: '📈' },
        { title: 'Culture Building', subtitle: 'Strengthen values', emoji: '🏛️' },
        { title: 'Value Proposition', subtitle: 'Clarify benefits', emoji: '💡' },
        { title: 'Revenue Growth', subtitle: 'Boost earnings', emoji: '💵' },
    ];    
      
    return (
        <div className="grid-container">
            <div className="grid-children">
                {children.map((child, index) => (
                    <div key={index} className="grid-circle">
                        <div className="emoji">{child.emoji}</div>
                        <h1>{child.title}</h1>
                        <h2>{child.subtitle}</h2>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default GridComponent;
