import React, { useState, useEffect } from "react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import AOS from "aos";
import "aos/dist/aos.css";

import CarouselSection from './CarouselSection/CarouselSection';

// Features Component
const FeatureCard = ({ icon, title, description }) => {
  return (
    <div className="flex flex-col items-center p-4 border border-gray-200 rounded-2xl shadow-sm hover:shadow-lg transition">
      <div className="text-purple-600 text-4xl mb-3">{icon}</div>
      <h3 className="text-lg font-semibold mb-2 text-center">{title}</h3>
      <p className="text-gray-600 text-sm text-center">{description}</p>
    </div>
  );
};

const Features = () => {
  const featuresData = [
    {
      icon: "🌟",
      title: "90 Day Free Replacement",
      description: "Guaranteed replacement if needed."
    },
    {
      icon: "💸",
      title: "Value-Driven, Scalable Rates",
      description: "Affordable solutions tailored to your needs."
    },
    {
      icon: "📉",
      title: "Attrition Management",
      description: "Helping reduce employee turnover."
    },
    {
      icon: "🚀",
      title: "Quick Response Time",
      description: "We prioritize fast and efficient service delivery."
    }
  ];

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 p-6">
      {featuresData.map((feature, index) => (
        <FeatureCard
          key={index}
          icon={feature.icon}
          title={feature.title}
          description={feature.description}
        />
      ))}
    </div>
  );
};

// Company Description Component
const CompanyDescription1 = ({ title, description }) => {
  return (
    <div className="flex flex-col md:flex-row items-center py-4 px-8 rounded-lg w-full">
      <div className="flex-shrink-0 w-full md:w-1/2">
        <img
          src="images/1.png"
          alt="Description"
          className="rounded-3xl border-2 shadow-2xl w-3/4 h-auto hover:scale-105 transition-transform duration-300 "
          style={{ maxHeight: "400px" }}
        />
      </div>
      <div className="mt-6 md:mt-0 md:ml-8 text-gray-700 w-full md:w-1/2">
        <h2 className="text-2xl font-bold mb-4 text-gray-800">{title}</h2>
        <p className="leading-relaxed mb-4">
          {description}
        </p>

      </div>
    </div>
  );
};

const CompanyDescription2 = ({ title, description }) => {
  return (
    <div className="flex flex-col md:flex-row items-center py-4 px-8 rounded-lg w-full">
      <div className="flex-shrink-0 w-full md:w-1/2">
        <h3 className="text-2xl font-bold mb-4 text-gray-800">{title}</h3>
        <p className="leading-relaxed mb-4">
          {description}
        </p>

      </div>
      {/* Image Section */}
      <div className="flex-shrink-0 w-full md:w-1/2 order-1 md:order-2 flex justify-end">
        <img
          src="images/4.png"
          alt="Description"
          className="rounded-3xl shadow-lg w-3/4 h-auto hover:scale-105 transition-transform duration-300"
          style={{ maxHeight: "400px" }}
        />
      </div>
    </div>
  );
};


const CompanyDescription3 = ({ title, description }) => {
  return (
    <div className="flex flex-col md:flex-row items-center py-4 px-8 rounded-lg w-full">
      <div className="flex-shrink-0 w-full md:w-1/2">
        <img
          src="images/3.png"
          alt="Description"
          className="rounded-3xl shadow-lg w-3/4 h-auto hover:scale-105 transition-transform duration-300"
          style={{ maxHeight: "400px" }}
        />
      </div>
      <div className="mt-6 md:mt-0 md:ml-8 text-gray-700 w-full md:w-1/2">
        <h1 className="text-2xl font-bold mb-4 text-gray-800">{title}</h1>
        <p className="leading-relaxed mb-4">
          {description}
        </p>

      </div>
    </div>
  );
};

// Recruitment Component
const Recruitment = () => {

  useEffect(() => {
    AOS.init({
      duration: 2000,
      once: true,
      offset: 50,
    });
  }, []);

  let sections = [
    {
      "title": "Why Choose Canaffor Corporation?",
      "description": "Canaffor Corporation stands out as one of the fastest-growing HR firms in India, offering end-to-end recruitment and outsourcing services. Our commitment to swift placements, top-quality talent, and personalized support ensures that we meet the unique needs of businesses across various industries. With over 40K successful placements and counting, we pride ourselves on delivering exceptional value to our clients."
    },
    {
      "title": "Our Approach",
      "description": "We believe in understanding your business inside out. Our process starts with gaining a deep insight into your goals, challenges, and company culture. From there, we leverage market research, benchmarking, and innovative sourcing techniques to find the perfect fit for your team. With 360-degree candidate assessments, we ensure every hire aligns with your business and cultural values."
    },
    {
      "title": "Our Process",
      "description": "At Canaffor, recruitment is more than just hiring—it’s about building long-term partnerships. We start by identifying your needs, followed by meticulous screening and shortlisting of candidates. Our team then organizes interview line-ups and provides you with comprehensive insights to make informed decisions. Throughout the process, we ensure timely communication, personalized support, and quick turnarounds to help you achieve your recruitment goals effortlessly."
    }
  ]

  const [activeIndex, setActiveIndex] = useState(null);

  const faqs = [
    {
      question: "What is Canaffor’s experience in recruitment?",
      answer:
        "With over 20 years of expertise, Canaffor has refined its recruitment skills to support hiring needs across various sectors and regions effectively.",
    },
    {
      question: "How satisfied are Canaffor’s clients?",
      answer:
        "All of Canaffor’s clients have chosen to continue their relationship with us, reflecting the high trust and satisfaction they have in our results and service.",
    },
    {
      question: "What makes Canaffor’s recruitment process unique?",
      answer:
        "Canaffor’s recruitment approach includes quality sourcing, quick turnaround times, and high retention rates for placements. We focus on finding the perfect business and cultural fit for each role.",
    },
    {
      question: "Does Canaffor specialize in any particular industries?",
      answer:
        "Canaffor has expertise across all verticals, enabling us to meet the unique hiring needs of a wide range of industries.",
    },
    {
      question: "How does Canaffor ensure a good fit for each role?",
      answer:
        "Our process includes headhunting, thorough candidate assessments, and a 360-degree evaluation to find the right fit for both the position and company culture.",
    },
    {
      question: "What candidate assessments does Canaffor use?",
      answer:
        "Canaffor uses a blend of psychometric tests, technical assessments, and performance-based interviews to thoroughly evaluate skills and competencies.",
    },
    {
      question: "How does Canaffor evaluate candidate potential?",
      answer:
        "We assess both immediate competencies and long-term potential through structured interviews and runway validation, ensuring candidates can thrive within our clients’ organizations.",
    },
    {
      question: "How quickly can Canaffor fill positions?",
      answer:
        "Canaffor is known for its quick turnaround time, efficiently managing the recruitment process to minimize hiring time and meet clients' needs promptly.",
    },
  ];

  const toggleFaq = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <div className="w-full my-10">
      {/* */}
      {/* <div data-aos="fade-up">
        <CarouselSection />
      </div>
  
      {/* Features Section */}
         <div data-aos="fade-up">
        <CarouselSection />
      </div>
      <div
        data-aos="fade-up"
        className="divider"
      ></div>
      <div className="max-w-5xl mx-auto" data-aos="fade-up">
      <h2 className="text-3xl font-bold text-center mt-6 mb-4">
          The Canaffor Promise
        </h2>
        <Features />
      </div>
      {/* Company Description */}
      <div className="w-full mb-8 my-15 px-20" data-aos="fade-up">
        <CompanyDescription1
          title={sections[0].title}
          description={sections[0].description}
        />
      </div>
      <div className="w-full mb-8 my-15 px-20" data-aos="fade-up">
        <CompanyDescription2
          title={sections[1].title}
          description={sections[1].description}
        />
      </div>
      <div className="w-full mb-8 my-15 px-20" data-aos="fade-up">
        <CompanyDescription3
          title={sections[2].title}
          description={sections[2].description}
        />
      </div>

      {/* FAQ Section */}
      <div className="max-w-5xl mx-auto p-6" data-aos="fade-up">
        <h2 className="text-3xl font-bold text-center mt-12 mb-6">
          Frequently Asked Questions
        </h2>
        <div>
          {faqs.map((faq, index) => (
            <div
              key={index}
              // className="border border-gray-300 rounded-lg overflow-hidden"
              data-aos="fade-up"
            >
              <button
                onClick={() => toggleFaq(index)}
                className="flex justify-between items-center w-full p-4 text-left text-lg font-semibold bg-gray-100 hover:bg-gray-200 focus:outline-none"
              >
                <span>{faq.question}</span>
                <span>{activeIndex === index ? "–" : "+"}</span>
              </button>
              {activeIndex === index && (
                <div className="p-4 text-gray-600 bg-white">{faq.answer}</div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>


  );
};

export default Recruitment;
