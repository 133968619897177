import React, { useState } from "react";


const questions = [
  {
    id: 1,
    text: "1 number",
    options: [
      "Strongly agree",
      "Agree",
      "Neither agree nor disagree",
      "Disagree",
      "Strongly disagree",
    ],
  },
  {
    id: 2,
    text: "2 number",
    options: [
      "Strongly agree",
      "Agree",
      "Neither agree nor disagree",
      "Disagree",
      "Strongly disagree",
    ],
  },
  {
    id: 3,
    text: "3 number",
    options: [
      "Strongly agree",
      "Agree",
      "Neither agree nor disagree",
      "Disagree",
 "Strongly disagree",
    ],
  },
  {
    id: 4,
    text: "4 number",
    options: [
      "Strongly agree",
      "Agree",
      "Neither agree nor disagree",
      "Disagree",
      "Strongly disagree",
    ],
  },
  {
    id: 5,
    text: "5 number",
    options: [
      "Strongly agree",
      "Agree",
      "Neither agree nor disagree",
      "Disagree",
      "Strongly disagree",
    ],
  },
  {
    id: 6,
    text: "6 number",
    options: [
      "Strongly agree",
      "Agree",
      "Neither agree nor disagree",
      "Disagree",
      "Strongly disagree",
    ],
  },
];

const SurveyTemplate = () => {
  const [currentIndex, setCurrentIndex] = useState(0); // Current question index

  const handleNext = () => {
    if (currentIndex + 3 < questions.length) {
      setCurrentIndex(currentIndex + 3);
    }
  };

  const handlePrevious = () => {
    if (currentIndex - 3 >= 0) {
      setCurrentIndex(currentIndex - 3);
    }
  };

  return (
    <div className="relative bg-gray-100 min-h-screen p-6">
      {/* Top-Left Section: Questions 1 and 2 */}
      <div className="absolute top-10 left-10 bg-white p-6 rounded-lg shadow-lg w-3/4">
        <div className="bg-green-500 px-4 py-3 rounded-lg mb-6">
          <h1 className="text-white text-xl font-semibold">
            SurveyMonkey Paradigm Belonging and Inclusion Template
          </h1>
        </div>

        {/* Question 1 */}
        <div className="mb-6 flex flex-col">
          <p className="text-gray-800 font-medium text-xl leading-6">
            1. {questions[currentIndex].text}
          </p>
          <div className="space-y-2 mt-2">
            {questions[currentIndex].options.map((option, index) => (
              <div key={index} className="flex items-center">
                <input
                  type="radio"
                  id={`q1-${index}`}
                  name="q1"
                  className="mr-2"
                />
                <label htmlFor={`q1-${index}`} className="text-gray-700 text-lg">
                  {option}
                </label>
              </div>
            ))}
          </div>
        </div>

        {/* Question 2 */}
        <div className="flex flex-col">
          <p className="text-gray-800 font-medium text-xl leading-6">
            2. {questions[currentIndex + 1] ? questions[currentIndex + 1].text : ''}
          </p>
          <div className="space-y-2 mt-2">
            {questions[currentIndex + 1]?.options.map((option, index) => (
              <div key={index} className="flex items-center">
                <input
                  type="radio"
                  id={`q2-${index}`}
                  name="q2"
                  className="mr-2"
                />
                <label htmlFor={`q2-${index}`} className="text-gray-700 text-lg">
                  {option}
                </label>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Bottom-Right Section: Question 3 with navigation buttons */}
      <div className="absolute bottom-10 right-10 bg-white p-6 rounded-lg shadow-lg w-2/4 h-2/4 flex flex-col">
        <p className="text-gray-800 font-medium text-xl leading-6">
          3. {questions[currentIndex + 2] ? questions[currentIndex + 2].text : ''}
        </p>
        <div className="space-y-2 mt-6 flex-1">
          {questions[currentIndex + 2]?.options.map((option, index) => (
            <div key={index} className="flex items-center">
              <input
                type="radio"
                id={`q3-${index}`}
                name="q3"
                className="mr-2"
              />
              <label htmlFor={`q3-${index}`} className="text-gray-700 text-lg">
                {option}
              </label>
            </div>
          ))}
        </div>

        {/* Navigation buttons */}
        <div className="mt-4 flex justify-between">
          <button
            onClick={handlePrevious}
            disabled={currentIndex <= 0}
            className="px-4 py-2 bg-gray-300 rounded-lg text-gray-800"
          >
            Previous
          </button>
          <button
            onClick={handleNext}
            disabled={currentIndex + 3 >= questions.length}
            className="px-4 py-2 bg-green-500 rounded-lg text-white"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default SurveyTemplate;
