import React from 'react';

function FeatureCard({ title, description, imagePath, bgColor }) {
  return (
    <div
      style={{ backgroundColor: bgColor }}
      className="flex-1 rounded-xl p-6 shadow-lg transform transition-transform duration-300 hover:scale-105"
    >
      <h2 className="text-2xl font-bold mb-4">{title}</h2>
      <p className="text-md mb-4">{description}</p>
      <div className="flex justify-center">
        <img src={imagePath} alt={title} className="max-h-40" />
      </div>
    </div>
  );
}

export default FeatureCard;
