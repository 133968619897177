import React, { useState } from "react";

const ChatSection = () => {
  const [openAccordion, setOpenAccordion] = useState("");

  const toggleAccordion = (section) => {
    setOpenAccordion(openAccordion === section ? null : section);
  };

  const accordionItems = [
    {
      title: "High attrition and low productivity?",
      content: "Canaffor provides solutions to improve retention, morale, and productivity, ensuring financial stability."
    },
    {
      title: "Struggling to scale or stabilize?",
      content: "Canaffor offers expert guidance for start-ups and growing companies to optimize operations and drive sustainable growth."
    },
    {
      title: "Stable but not profitable?",
      content: "Canaffor identifies profitability gaps and helps optimize resources to enhance the bottom line."
    },
    {
      title: "Low morale despite high profits?",
      content: "Canaffor boosts employee engagement and reduces attrition through targeted morale-boosting programs."
    },
    {
      title: "Performance stagnation and low productivity?",
      content: "Canaffor rejuvenates stagnation by streamlining workflows and promoting continuous improvement."
    },
    {
      title: "Struggling with expansion and team redeployment?",
      content: "Canaffor helps optimize resources and redeploy teams effectively to support growth initiatives."
    },
    {
      title: "Excess manpower or under-staffing?",
      content: "Canaffor helps restructure teams and address staffing challenges for better productivity and resource utilization."
    },
    {
      title: "Well-paid employees, but low output?",
      content: "Canaffor helps diagnose productivity issues and implement solutions to improve efficiency and employee motivation."
    },
    {
      title: "Growth misaligned with company vision?",
      content: "Canaffor realigns strategy and operations to ensure growth is in sync with your company’s vision."
    },
    {
      title: "Struggling with talent retention or skills development?",
      content: "Canaffor provides retention strategies and skill development programs to enhance employee growth and reduce turnover."
    }
  ];


  return (
    <div className="flex flex-col md:flex-row min-h-screen">
      <div className="w-full md:w-1/2 p-6">
        {accordionItems.map((item) => (
          <div
            key={item.title}
            className="border-b border-gray-200 py-4 cursor-pointer"
          >
            <div
              className="flex justify-between items-center"
              onClick={() => toggleAccordion(item.title)}
            >
              <h2 className="text-lg font-semibold text-gray-800">
                {item.title}
              </h2>
              <span className="text-gray-500">
                {openAccordion === item.title ? "−" : "+"}
              </span>
            </div>
            {openAccordion === item.title && (
              <p className="text-sm text-gray-600 mt-2">{item.content}</p>
            )}
          </div>
        ))}
      </div>
      <div className="w-full md:w-1/2 p-6 flex items-center justify-center">
        <div className="p-4 rounded-3xl bg-white shadow-2xl">
          <img
            src={`${process.env.PUBLIC_URL}/images/consulting.png`}
            alt="Consulting Preview"
            className="min-h-full max-w-full h-auto rounded-3xl"
          />
        </div>
      </div>
    </div>
  );
};

export default ChatSection;
