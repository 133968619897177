import { React, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import './index.css';
import Navbar from './Component/Navbar/Navbar';
import Home from './Component/Home/Home'

import Footer from './Component/Footer/Footer';
import ContactUs from './Component/Contact/Contact'
import Recuritment from './Component/Recruitment/Recruitment';
import HR_CONSULTING from './Component/HR_CONSULTING/Hr_consulting';
import ReactGA from 'react-ga4';
import Analytics from './Component/Analytics';
import { TrackingId } from './Component/Constant';
import PrivacyPolicy from './Component/PrivacyPolicy/PrivacyPolicy';
import Survey from './Component/Survey/Survey'

const App = () => {
  useEffect(() => {
    ReactGA.initialize([
      {
        trackingId: TrackingId,
      }
    ]);

    ReactGA.send({ hitType: "pageview", page: "/my-path", title: "Custom Title" });
  }, []);

  return (
    <>
      <Navbar />
  <Analytics/>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/recruitment" element={<Recuritment />} />
        <Route path="/hr_consulting" element={<HR_CONSULTING />} />
        <Route path="/privacy_policy" element={<PrivacyPolicy />} />
        <Route path="/survey" element={<Survey />} />
      </Routes>
      <Footer />
    </>
  );
};

export default App;
