import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import { Pagination, Autoplay } from "swiper/modules";
import { useNavigate } from "react-router-dom";

import "aos/dist/aos.css";


const CarouselSection = () => {
    const navigate = useNavigate();
    const handleNavigate = () => {
        window.scrollTo(0, 0);
        navigate('/contact');
    };

    return (
        <div className="bg-white">
            <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-12 items-center p-8">

                {/* Left Section */}
                <div>
                    <h1 className="text-5xl font-bold text-black mb-6 leading-tight">
                        Connecting Talent, <br /> Driving Success.
                    </h1>
                    <h4 className="text-lg text-gray-600 mb-6">
                        Ready to find top talent? <br></br> Partner with Canaffor to access top talent across any sector, role, and location, tailored to your unique business needs.
                    </h4>
                    <button className="bg-black text-white px-6 py-3 rounded-lg hover:bg-gray-800 transition mb-4" onClick={handleNavigate}>
                        Get In Touch
                    </button>

                </div>

                {/* Right Section: Carousel */}
                <div className="rounded-3xl  shadow-slate-300  p-4">
                    <Swiper
                        modules={[Pagination, Autoplay]}
                        pagination={{ clickable: true }}
                        loop={true}
                        autoplay={{
                            delay: 3000,
                            disableOnInteraction: false,
                        }}
                        className="rounded-lg"
                    >
                        <SwiperSlide>
                            <img
                                src="images/1.png"
                                alt="Slide 1"
                                className="w-full h-auto rounded-2xl"
                            />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img
                                src="images/5.png"
                                alt="Slide 2"
                                className="w-full h-auto rounded-2xl"
                            />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img
                                src="images/4.png"
                                alt="Slide 4"
                                className="w-full h-auto rounded-2xl"
                            />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img
                                src="images/3.png"
                                alt="Slide 3"
                                className="w-full h-auto rounded-2xl"
                            />
                        </SwiperSlide>

                    </Swiper>
                </div>
            </div>
        </div>
    );
};

export default CarouselSection;