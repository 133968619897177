import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css'; // Import the external styles
import { handleRedirect } from '../Common_function'; // Import the common function
import { CONTACT_INFO, SOCIAL_MEDIA_LINKS, COMPANY_DETAILS, APP_COLOR } from "../Constant";


const Footer = () => {
  return (
    <footer className="relative bg-[#202020] pt-8 pb-6 text-white">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap text-left lg:text-left">
          <div className="w-full lg:w-6/12 px-4">
            <h4 className="text-3xl font-semibold">Let's keep in touch!</h4>
            <h5 style={{ color: APP_COLOR }} className="text-lg mt-0 mb-6 text-cyan-400">
              Find us on any of these platforms, we respond 1-2 business days.
            </h5>
            <div className="mt-6 lg:mb-0 mb-6 flex">
              <button
                className="social_icons footer-icon-button fb"
                type="button"
                onClick={() => handleRedirect(SOCIAL_MEDIA_LINKS.facebook)}
              >
                <i className="fab fa-facebook-f"></i>
              </button>
              <button
                className="social_icons footer-icon-button ig"
                type="button"
                onClick={() => handleRedirect(SOCIAL_MEDIA_LINKS.instagram)}
              >
                <i className="fab fa-instagram"></i>
              </button>
              <button
                className="social_icons footer-icon-button tw"
                type="button"
                onClick={() => handleRedirect(SOCIAL_MEDIA_LINKS.twitter)}
              >
                <i className="fab fa-twitter"></i>
              </button>
              <button
                className="social_icons footer-icon-button li"
                type="button"
                onClick={() => handleRedirect(SOCIAL_MEDIA_LINKS.linkedin)}
              >
                <i className="fab fa-linkedin-in"></i>
              </button>
              <button
                className="social_icons footer-icon-button yt"
                type="button"
                onClick={() => handleRedirect(SOCIAL_MEDIA_LINKS.youtube)}
              >
                <i className="fab fa-youtube"></i>
              </button>
            </div>
            <div className="flex flex-wrap items-top mt-6">
              <div className="w-full lg:w-6/12 px-4">
                <Link to="/contact" style={{ color: APP_COLOR }} className="block uppercase text-cyan-400 text-sm font-semibold mb-2">Contact Us</Link>
                <ul className="list-unstyled text-white">
                  <li><span style={{ color: APP_COLOR }} className="text-cyan-400">For Business Enquiries: </span>{CONTACT_INFO.info_email}</li>
                  <li><span style={{ color: APP_COLOR }} className="text-cyan-400">Mail Your CV: </span>{CONTACT_INFO.cv_email}</li>
                  <li><Link to="/privacy_policy" style={{ color: APP_COLOR }} className="text-cyan-400">Privacy Policy</Link></li>
                  <li><Link to="/survey" style={{ color: APP_COLOR }} className="text-cyan-400">Survey</Link></li>
                </ul>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <span style={{ color: APP_COLOR }} className="block uppercase text-cyan-400 text-sm font-semibold mb-2">Our Address</span>
                <ul className="list-unstyled text-white">
                  <li><span style={{ color: APP_COLOR }} className="text-cyan-400">Headquarters:</span> {COMPANY_DETAILS.address}</li>
                  <li><span style={{ color: APP_COLOR }} className="text-cyan-400">Call for HR Advisory:</span> {CONTACT_INFO.hr_advisiory_phone}</li>
                  <li><span style={{ color: APP_COLOR }} className="text-cyan-400">Call for Recruitment:</span> {CONTACT_INFO.recruitment_supportPhone}</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4 lg:text-left">
            
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
