import React from "react";
import { Link } from 'react-router-dom';
import './AboutUs.css';
import { images } from '../Constant'

function AboutUs() {
  return (
    <section className="about-section flex flex-col-reverse sm:flex-row items-center max-w-screen-xl mx-auto px-5 sm:px-10">
      <div className="about-content w-full sm:w-1/2 px-5">
        <div className="flex flex-col items-start">
          <h1 className="about-title">About Us 🚀</h1>
          <p className="about-paragraph">
            <b>Canaffor Corporation</b>, is your one-stop solution for
            <b> all things HR!</b>
          </p>
          <p className="about-paragraph">
            We’re proud to be one of the <b>fastest-growing</b> HR companies in India 🌏,
            working with top Indian businesses and global MNCs across the world for <b>more than three decades.</b>
          </p>
          <br></br>
          <p className="about-paragraph">
            Empowering Indian corporates to scale up & thrive in local & global markets.
            {/* We’ve placed over <b>1 lakh</b> candidates across 30+ sectors, offering personalized guidance to help you find roles that align with your goals. */}
          </p>
          <br></br>
          <div className="data">
            <Link to="/contact" className="button contact-button">
              <div className="dots_border"></div>
              <span className="text_button">Contact us</span>
            </Link>
          </div>
        </div>
      </div>
      <div className="image-container w-full sm:w-1/2 p-5">
        <img
          src={images.about_img}
          alt="About Us"
          className="about-image"
        />
      </div>
    </section>
  );
}

export default AboutUs;
