import React, { useState, useEffect } from "react";
import './testimonial.css'

const testimonials = [
    {
        id: 1,
        text: "Vibrant and high energy Organization. Very focused in their approach and quick turnaround time. Appreciate the amazing responsiveness from the teams at Canaffor!",
        name: "Sanjiva Jha",
        position: "Client",
        location: "Mumbai, India",
        companyLogo: "",
    },
    {
        id: 2,
        text: "Loved the experience! \nVijay, Gayatri and the entire team helped us get the required number of skilled players for which we’re grateful for.",
        name: "Kashish Rupani",
        position: "Big 4 Consulting Firm",
        location: "Mumbai, India",
        companyLogo: "",
    },
    {
        id: 3,
        text: "Pleasantly surprised by how my requirements were met with exceptional results, working with them was a great experience.",
        name: "Hrishit Jhaveri",
        position: "Director, Kripa Jewellers",
        location: "Mumbai, India",
        companyLogo: "https://static.wixstatic.com/media/7493f2_2c22ae3edac44432af0220f9381451aa~mv2.jpg/v1/fill/w_968,h_250,al_c,q_80,usm_0.66_1.00_0.01,enc_avif,quality_auto/kripa-logo_edited.jpg",
    },
    {
        id: 4,
        text: "Canaffor's services exceeded expectations. A professional and highly motivated team. Truly a pleasure working with them.",
        name: "Amit Patel",
        position: "CEO",
        location: "Delhi, India",
        companyLogo: "",
    },
];

const Testimonials = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [itemsToShow, setItemsToShow] = useState(2); 
    const [animationClass, setAnimationClass] = useState("");

    useEffect(() => {
        const updateItemsToShow = () => {
            if (window.innerWidth >= 1024) {
                setItemsToShow(2);
            } else if (window.innerWidth >= 768) {
                setItemsToShow(2);
            } else {
                setItemsToShow(1);
            }
        };

        updateItemsToShow();
        window.addEventListener("resize", updateItemsToShow);

        return () => window.removeEventListener("resize", updateItemsToShow);
    }, []);

    const handlePrev = () => {
        setAnimationClass("slide-out-right");
        setTimeout(() => {
            setCurrentIndex((prevIndex) =>
                prevIndex === 0
                    ? testimonials.length - itemsToShow
                    : prevIndex - 1
            );
            setAnimationClass("slide-in-left");
        }, 300);
    };

    const handleNext = () => {
        setAnimationClass("slide-out-left");
        setTimeout(() => {
            setCurrentIndex((prevIndex) =>
                (prevIndex + itemsToShow) >= testimonials.length
                    ? 0
                    : prevIndex + 1
            );
            setAnimationClass("slide-in-right");
        }, 300);
    };

    return (
        <div className="bg-white py-8">
            <div className="container mx-auto px-6 text-center">
                <h2 className="text-3xl font-bold text-gray-800 mb-8">What Our Clients Say</h2>
                <div className="flex items-center justify-between gap-4 w-full mx-auto">
                    <button
                        onClick={handlePrev}
                        className="bg-black text-white p-2 md:p-3 rounded-full hover:bg-gray-700"
                        style={{ width: "3rem", height: "3rem" }}
                        aria-label="Previous"
                    >
                        &#8592;
                    </button>

                    <div className="flex overflow-hidden w-full justify-around gap-4 px-4 py-5">
                        {testimonials
                            .slice(currentIndex, currentIndex + itemsToShow)
                            .map((testimonial) => (
                                <div
                                    key={testimonial.id}
                                    className={`testimonial-card bg-slate-100 shadow-xl rounded-lg p-8 w-full max-w-md flex flex-col justify-center text-left transform transition-transform duration-300 hover:scale-105 ${animationClass}`}
                                >
                                    <div className="flex items-center mb-6 justify-center">
                                        {testimonial.companyLogo && (
                                            <img
                                                src={testimonial.companyLogo}
                                                alt="Company logo"
                                                className="h-14"
                                                loading="lazy"
                                            />
                                        )}
                                    </div>
                                    <div>
                                        <img
                                            src={`${process.env.PUBLIC_URL}/images/inverted_comma.png`}
                                            className="h-5 pb-2"
                                            alt="inverted comma"
                                        ></img>
                                    </div>
                                    <p className="text-black font-semibold mb-8 text-2xl text-left">
                                        {testimonial.text}"
                                    </p>
                                    <div className="text-left ml-0">
                                        <p className="font-semibold text-gray-800 text-lg text-left">
                                            {testimonial.name}
                                        </p>
                                        <p className="text-gray-500 text-left">{testimonial.position}</p>
                                        <p className="text-gray-500 text-left">{testimonial.location}</p>
                                    </div>
                                </div>
                            ))}
                    </div>

                    <button
                        onClick={handleNext}
                        className="bg-black text-white p-2 md:p-3 rounded-full hover:bg-gray-700"
                        style={{ width: "3rem", height: "3rem" }}
                        aria-label="Next"
                    >
                        &#8594;
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Testimonials;
